<template>
        <div class="el-input el-input--medium" :class="{'is-disabled':disabled}"
             style="margin-bottom: 0">
            <div class="el-input__inner" style="padding: 0 5px;">
                <div class="w-100 h-100 position-relative">
                    <div class="single-files-upload w-100 h-100 overflow-hidden">
                        <div class="attachment" v-if="list && !disabled">
                            <el-button class="action-button" plain circle type="danger" icon="el-icon-delete" size="mini"
                                       style="padding: 7px;margin-top: 3px" @click="deleteAttachment">
                            </el-button>
                            <div style="max-width: 326px;width: 100%;text-align: center">
                                <i class="el-icon-paperclip"></i> {{list.name}}
                            </div>
                        </div>
                        <el-upload
                                :disabled="disabled"
                                class="uploader"
                                :action="url"
                                :show-file-list="false"
                                :on-success="handleFileSuccess"
                                :before-upload="beforeFileUpload"
                                :on-progress="handleFileProgress"
                                :on-error="handleUploadError"
                                :data="{type: 'attachment'}"
                                :accept="accept"
                                :headers="headers">
                            <el-button :disabled="disabled" class="add-btn" size="small" type="text"
                                       :icon="noIcon?null:'el-icon-upload2'" v-if="disabled || !list">
                                {{title}}
                            </el-button>
                        </el-upload>
                    </div>
                    <div style="position: absolute;left: 0;top:0;right: 0;bottom:0;background-color: rgba(255,255,255,0.9);"
                         v-if="progress > 0 && progress < 100">
                        <div class="d-flex justify-content-center align-items-center text-primary h-100">
                            <i class="el-icon-loading" ></i> 上传中... {{progress}}%
                        </div>
                    </div>
                </div>
            </div>
        </div>

</template>

<script>
    import api from "../config/api.js"
    import {mapState} from "vuex"

    export default {
        name: "single-file-upload",
        components: {},
        data() {
            return {
                list: this.attachment,
                headers: null,
                progress: 0,
                token: null,
                url:null,
            }

        },
        model: {
            prop: 'attachment',
            event: 'input'
        },
        props: {
            attachment: {
                type: Object,
                default() {
                    return null;
                },
            },
            upload_url:{
                type: String,
                default() {
                    return null;
                }
            },
            extensions: {
                type: Array,
                default() {
                    return ['zip', 'txt', 'doc', 'docx', 'ppt', 'pptx', 'pdf', 'jpg', 'jpeg', 'png', 'gif', 'xls', 'xlsx'];
                }
            },
            accept: {
                type: String,
                default() {
                    return null;
                }
            },
            fileSize: {
                type: Number,
                default() {
                    return 20;
                }
            },
            disabled: {
                type: [Boolean, Number],
                default() {
                    return false;
                }
            },
            title: {
                type: String,
                default() {
                    return '';
                }
            },
            noIcon:{
                type:Boolean,
                default() {
                    return false;
                }
            }
        },
        computed: {
            ...mapState(['user']),
            support_error(){
                return '上传仅仅支持 %s 并且文件小于 %s'.arrayReplace( [this.extensions.join(','), this.fileSize + 'M']);
            }
        },
        watch: {
            attachment: {
                handler(newVal) {
                    this.list = newVal;
                }
            }
        },
        created() {
            this.url = this.upload_url?this.$apiUrl(this.upload_url):api.url + api.modules.User.UploadAttachment;
            this.token = this.$store.state.user.token;
            this.headers = {'user-token': this.token, 'Accept': 'application/json'};
        },
        methods: {
            handleFileSuccess(res) {
                if (res.success) { // success
                    this.list = res.data;
                    this.$emit('input', this.list);
                } else {
                    this.$messageBox.error(res.text);
                }
            },
            handleUploadError(err) {
                console.log(err);
                this.progress = 0;
                this.$messageBox.error(this.support_error);
            },
            beforeFileUpload(file) {
                const sizeCheck = file.size / 1024 / 1024 <= this.fileSize;
                const split = file.name.split('.');
                const ext = split[split.length - 1].toLocaleLowerCase();
                const extCheck = (-1 !== this.extensions.indexOf(ext));

                if (!extCheck) {
                    this.$messageBox.error(this.support_error);
                } else {
                    if (!sizeCheck) {
                        this.$messageBox.error(this.support_error);
                    }
                }
                return extCheck && sizeCheck;
            },
            handleFileProgress(event) { //file,fileList
                let vm = this;
                let percent = Math.ceil(event.percent);
                if (percent === 100) {
                    vm.progress = 99;
                    vm.$nextTick(() => {
                        vm.progress = 100
                    });
                } else {
                    vm.progress = percent
                }
            },
            deleteAttachment() {
                const vm = this;
                this.$affirm('确定要删除此附件吗?').then(() => {
                    vm.list = null;
                    this.$emit('input',vm.list);
                }).catch(() => {
                })
            }
        }
    }
</script>

<style lang="scss">
    @import "~@/scss/global";

    .single-files-upload {
        .attachment {
            position: relative;
            font-size: 14px;
            color: #9a9a9a;
            box-sizing: border-box;
            cursor: pointer;

            .action-button {
                position: absolute;
                right: 3px;
                top:0;
                display: none;
            }

            &:hover {
                color: $--color-primary;
                .action-button {
                    display: block;
                }
            }

            & > div {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        .uploader {
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;

            .el-upload {
                display: block;

                .add-btn {
                    width: 100%;
                    max-width: 326px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }
    }


</style>
