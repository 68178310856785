<template>
    <section class="table-section">
        <!--工具条-->
        <el-col :span="24" class="toolbar" style="padding-bottom: 0;">
            <!-- tool button -->
            <slot></slot>
            <div class="pull-right el-form el-form--inline" v-if="front_extra">
                <front-single-extra-filter v-for="(x,key) in front_extra"
                                           :key="key"
                                           :item="Object.assign({key:key},x)"
                                           @change="handleExtraFilter"
                                           :extra="search.extra">
                </front-single-extra-filter>
            </div>
            <el-form :inline="true" :model="search" @submit.native.prevent>
                <el-form-item>
                    <el-input size="medium" clearable v-model="search.search" @clear="handleSearch"
                              @keyup.native="debounced()"
                              :placeholder="('搜索')"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button size="medium" type="primary" plain @click="handleSearch" icon="el-icon-search">
                        {{ ('搜索') }}
                    </el-button>
                </el-form-item>
                <el-form-item v-if="btn_add" class="ml-2">
                    <el-button size="medium" icon="el-icon-circle-plus-outline" type="primary" @click="handleAdd(0)">
                        {{ ('增加') }}{{ $route.meta ? ($route.meta.friendly) : '' }}
                    </el-button>
                </el-form-item>
            </el-form>
        </el-col>

        <!--列表-->
        <el-table :data="records" @sort-change="sortIsChange" v-loading="process.loading && show_loading"
                  @selection-change="doSelect" @filter-change="doFilter" size="medium"
                  style="width: 100%;" :border="true" v-show="header.length">
            <el-table-column type="selection" :width="40" :selectable="checkSelectable">
            </el-table-column>
            <!--<el-table-column :width="36" v-if="btn_edit">
                <template slot-scope="scope">
                    <el-button size="small" type="text"
                               @click="handleEdit(scope.$index, scope.row)" icon="el-icon-edit">
                    </el-button>
                </template>
            </el-table-column> -->
            <el-table-column v-for="(item) in filter_header"
                             :width="item.width || 'auto'"
                             :key="item.key" :prop="item.key"
                             :label="english?(item.title_en?item.title_en:item.title):item.title"
                             :sortable="item.sortable?'custom':null"
                             :filters="item.filters?(english?(item.filters_en || item.filters):item.filters):null"
                             :filter-multiple="false">
                <template #default="scope">
                    <div v-if="item.type==='picture'">
                        <div v-for="pic in scope.row[scope.column.property]"
                             :key="pic.id" style="display: inline-block;cursor: zoom-in;"
                             @click="handlePictureOpen(pic)">
                            <img alt="picture" :src="pic.thumbnail" style="height: 40px;margin-right: 5px;">
                        </div>
                    </div>
                    <div v-else v-html="scope.row[scope.column.property]"></div>
                </template>
            </el-table-column>
            <el-table-column :label="('操作')" :width="calculateWidth">
                <template #default="scope">

                    <el-dropdown size="small" split-button type="primary" @click="handleClick(scope.$index, scope.row)"
                                 @command="handleCommand" v-if="table_buttons.length"
                                 :disabled="scope.row.should_not_edit && ( table_buttons[0].id==='edit' ||  table_buttons[0].id==='delete')">
                        <i :class="table_buttons[0].icon"></i> {{ table_buttons[0].name }}
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(x) in rest_table_buttons" :key="x.id" :icon="x.icon"
                                              :class="x.class || ''"
                                              :command="{command:x,index:scope.$index, row:scope.row}"
                                              :disabled="scope.row.should_not_edit && (x.id==='edit' || x.id==='delete')">
                                {{ x.name }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>

                    <!---
                    <el-button v-if='btn_review' size="small" @click="handleReview(scope.$index, scope.row)" circle
                               icon="el-icon-search"></el-button>
                    <el-button v-if='btn_edit' :disabled="scope.row.should_not_edit" size="small"
                               @click="handleEdit(scope.$index, scope.row)" circle icon="el-icon-edit"></el-button>
                    <el-button v-if='btn_copy' type="info" size="small"
                               @click="handleCopy(scope.$index,scope.row)" circle
                               icon="el-icon-copy-document"></el-button>
                    <el-button v-if='btn_delete' :disabled="scope.row.should_not_edit" type="danger" size="small"
                               @click="handleDel(scope.$index, scope.row)" circle icon="el-icon-close"></el-button>
                    <el-button v-if='btn_download' type="info" size="small"
                               @click="handleDownload(scope.$index, scope.row)" circle icon="el-icon-download"></el-button>
                    --->
                </template>
            </el-table-column>
        </el-table>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-button v-for="x in btn_extra_batch" :key="x.name" size="medium" :type="x.type" :icon="x.icon"
                       @click="handleExtraBatch(x)">
                {{ x.name }}
            </el-button>

            <el-button size="medium" v-if="btn_delete" type="danger" @click="handleBatchDel"
                       :disabled="this.selects.length===0"
                       icon="el-icon-delete">{{ ('批量删除') }}
            </el-button>

            <slot name="footer"></slot>

            <el-pagination layout="total,prev,pager,next" :page-size="page.size" :current-page="page.current"
                           :total="page.total" style="float:right;" @current-change="handleCurrentChange">
            </el-pagination>
        </el-col>

        <el-dialog v-picture-viewer
                   :visible.sync="show_pic"
                   append-to-body>
            <div style="max-height: calc( 80vh - 63px );max-width:100%;overflow: auto;" class="">
                <img :src="single.picture" class="large-image enlarge" alt="">
            </div>
        </el-dialog>
    </section>
</template>

<script>
import {mapState} from 'vuex';
import FrontSingleExtraFilter from "@/components/front-single-extra-filter";

export default {
    name: "front-table",
    components: {FrontSingleExtraFilter},
    data() {
        return {
            single: {},
            show_pic: false,
            is_first: true,
            selects: [],
            page: this.front_page,
            search: this.front_search,
            records: this.front_records,
            debounced: null,
            header: this.front_header,
        }
    },
    watch: {
        front_page: {
            handler(newVal) {
                this.page = newVal
            },
        },
        front_records: {
            handler(newVal) {
                this.records = newVal
            },
            deep: true
        },
        front_header: {
            handler(newVal) {
                this.header = newVal
            },
            deep: true
        }
    },
    props: {
        front_page: {
            type: Object,
            default() {
                return {}
            }
        },
        front_search: {
            type: Object,
            default() {
                return {}
            }
        },
        front_records: {
            type: Array,
            default() {
                return []
            }
        },
        front_header: {
            type: Array,
            default() {
                return []
            }
        },
        front_extra: {
            type: Object,
            default() {
                return null
            }
        },
        model: {
            type: String,
            default() {
                return '';
            }
        },
        btn_review: {
            type: Boolean,
            default() {
                return false;
            }
        },
        btn_add: {
            type: Boolean,
            default() {
                return true;
            }
        },
        btn_edit: {
            type: Boolean,
            default() {
                return true;
            }
        },
        btn_delete: {
            type: Boolean,
            default() {
                return true;
            }
        },
        btn_copy: {
            type: Boolean,
            default() {
                return false;
            }
        },
        btn_download: {
            type: Boolean,
            default() {
                return false;
            }
        },
        btn_extra_batch: {
            type: Array,
            default() {
                return [];
            }
        },
        show_loading: {
            type: Boolean,
            default() {
                return true;
            }
        }
    },
    methods: {
        handleClick(index, row) {
            this.table_buttons[0].func(index, row);
        },
        handleCommand(command) {
            command.command.func(command.index, command.row);
        },
        checkSelectable(row) {
            return (!row.should_not_edit);
        },
        doSelect(selection) {
            this.selects = selection;
        },
        sortIsChange(column) {
            this.search.sort = {'prop': column.prop, 'order': column.order};
            this.$emit('reload', this.search);
        },
        doFilter(filters) {
            this.search.filters = Object.assign({}, this.search.filters, filters);
            this.$emit('reload', this.search);
        },
        handleEdit(index, row) {
            this.handleAdd(row.id);
        },
        handleAdd(id) {
            this.$emit('edit', id);
        },
        handleDownload(index, row) {
            this.$emit('download', row.id);
        },
        handleCopy(index, row) {
            this.$emit('copy', row.id);
        },
        handleReview(index, row) {
            this.$emit('review', row.id);
        },
        handleDel(index, row) {
            this.$confirm(('您确认删除该记录吗'), ('提示'), {
                type: 'warning',
                dangerouslyUseHTMLString: true

            }).then(() => {
                this.$api(this.model + '.Delete', {id: row.id}, true)
                    .then(() => {
                        this.$notify({
                            message: ('删除记录成功!'),
                            type: 'success'
                        });
                        this.$emit('reload', this.search);
                    });
            }).catch(() => {
            });
        },
        handleBatchDel() {
            let vm = this;
            this.$confirm(('您确认删除这些记录吗?'), ('信息'), {
                type: 'warning',
                dangerouslyUseHTMLString: true

            }).then(() => {
                this.$api(vm.model + '.Delete', {ids: vm.selects.map(i => i.id)}, true)
                    .then(() => {
                        this.$notify({
                            message: ('删除记录成功!'),
                            type: 'success'
                        });
                        this.$emit('reload', this.search);
                    });
            }).catch(() => {
            });
        },
        handleExtraBatch(item) {
            this.$emit('extra', {item: item, ids: this.selects.map(i => i.id)});
        },
        handleCurrentChange(val) {
            this.search.page = val;
            this.$emit('reload', this.search);
        },
        handleSearch() {
            this.$emit('reload', this.search);
        },
        handlePictureOpen(pic) {
            this.single = pic;
            this.show_pic = true;
        },
        handleExtraFilter() {
            this.handleSearch();
        },
    },
    computed: {
        ...mapState(['process']),
        defaultSort() {
            let order = {};
            if (this.header.length > 0) {
                this.header.forEach(h => {
                    if (h.default) {
                        order = {prop: h.key, order: h.default === 'asc' ? 'ascending' : 'descending'};
                    }
                });
            }
            return order;
        },
        filter_header() {
            return this.header.filter(function (item) {
                return !item.hidden;
            })
        },
        english() {
            return false;
        },
        calculateWidth() {
            return 140;
            //let width = (this.btn_download ? 1 : 0) + (this.btn_review ? 1 : 0) + (this.btn_edit ? 1 : 0) + (this.btn_copy ? 1 : 0) + 1;
            //return width * 50;
        },
        table_buttons() {
            let bs = [];
            if (this.btn_review) bs.push({id: 'review', name: '查看', icon: 'el-icon-search', func: this.handleReview});
            if (this.btn_edit) bs.push({
                id: 'edit',
                name: '编辑',
                icon: 'el-icon-edit',
                func: this.handleEdit,
                class: 'text-primary'
            });
            if (this.btn_copy) bs.push({
                id: 'copy',
                name: '复制',
                icon: 'el-icon-copy-document',
                func: this.handleCopy
            });
            if (this.btn_delete) bs.push({
                id: 'delete',
                name: '删除',
                icon: 'el-icon-delete',
                class: 'text-danger',
                func: this.handleDel
            });
            if (this.btn_download) bs.push({
                id: 'download',
                name: '报表',
                icon: 'el-icon-download',
                class: 'text-info',
                func: this.handleDownload
            });
            return bs;
        },
        rest_table_buttons() {
            return this.table_buttons.filter((x, i) => {
                return i > 0
            });
        },
    },
    mounted() {
        let vm = this;
        this.debounced = this.$debounce(1000, function () {
            vm.$emit('reload', vm.search);
        });
    },
}
</script>

<style lang="scss">

.table-section {
    //border: 1px solid #ccc !important;
    height: 100%;

    .toolbar {
        position: relative;
        font-size: 12px;
        border: 1px solid #ebeef5;

        background: #fff;
        padding: 5px 10px;
        //border:1px solid #dfe6ec;
        margin: 10px 0;

        .el-form-item {
            margin-bottom: 5px;
        }
    }

    .el-table {
        thead {
            tr {
                background-color: #d9e5f1;

                th {
                    color: #37474f;
                    background-color: #d9e5f1;

                    .cell {
                        line-height: 34px;
                    }

                    .el-table__column-filter-trigger {
                        float: right;
                    }

                    i.el-icon-arrow-down {
                        transform: scale(1.3);
                        //margin-left: 5px;
                        transition: all 0.1s ease-in;

                        &:before {
                            content: "\e6ca";
                        }

                        &:hover {
                            transform: scale(1.8);
                            color: #3794ff;
                        }
                    }

                    .highlight {
                        i.el-icon-arrow-down {
                            color: #3794ff;
                        }
                    }
                }
            }
        }
    }
}

.large-image {
    cursor: zoom-out;

    &.enlarge {
        max-width: 100%;
        max-height: calc(80vh - 63px);
        cursor: zoom-in;
    }
}

</style>
