import {mapState} from "vuex";

export default {
    data() {
        return {
            model: null,
            single: {},
            header: [],
            fields: {},
            extra_filters:{},
            edit_mode: false,
            view_mode: false,
            page: {
                total: 0,
                display: 1,
                current: 0,
                count: 0,
                size: 20
            },
            search: {
                search: '',
                filters: [],
                page: 1,
                sort: {},
                date_rage: [],
                type:0,
                extra:{},
            },
            old: null,
            old_filters:null,
            old_date:null,
            records: [],
            is_first: true,
        }
    },
    computed: {
        ...mapState(['user', 'preset', 'process']),
        canAdd() {
            return true;
        },
        can_copy() {
            return this.$route.meta ? this.$route.meta.copy : false;
        },
        can_add() {
            return this.$route.meta ? !this.$route.meta.no_add : true
        },
        can_delete() {
            return this.$route.meta ? !this.$route.meta.no_delete : true
        },
        can_edit() {
            return this.$route.meta ? !this.$route.meta.no_edit : true
        }

    },
    methods: {
        reload() {
            let vm = this;
            let obj = Object.assign({},this.search);
            delete obj.page;
            const diff = JSON.stringify(obj);
            if (this.old !== diff) {
                this.search.page = 1;
                this.old = diff;
            }
            this.beforeReload();
            this.$api(this.model + '.List', this.search, true).then(response => {
                if (response.data.data) {
                    if (vm.is_first) {
                        vm.header = response.data.data.header;
                        vm.extra_filters = response.data.data.extra_filters;
                        let extra = {};
                        if (vm.extra_filters) {
                            for (let key in vm.extra_filters) {
                                extra[key] = vm.extra_filters[key].default;
                            }
                        }
                        vm.$set(vm.search,"extra",extra);
                        vm.afterReload();
                        let obj = Object.assign({},this.search);
                        delete obj.page;
                        vm.old = JSON.stringify(obj);
                    }
                    vm.records = response.data.data.data;
                    vm.page = Object.assign(vm.page, response.data.data.page);
                    vm.is_first = false;
                }
            });
        },
        handleSuccess() {
            this.edit_mode = false;
            this.reload();
        },
        handleCancel() {
            this.edit_mode = false;
        },
        handleEdit(id) {
            let vm = this;
            this.$api(vm.model + '.Get', {id: id}, true).then(response => {
                vm.single = response.data.data.data || {};
                vm.fields = response.data.data.fields;
                if (this.processData) this.processData();
                vm.edit_mode = true
                Object.keys(vm.fields).forEach(function (key) {
                    let x = vm.fields[key];
                    if (x.type === 'picture' && !vm.single[key]) {
                        vm.single[key] = [];
                    }
                });
            });
        },
        handleCopy(id) {
            let vm = this;
            this.$api(vm.model + '.Get', {id: id}, true).then(response => {
                vm.single = response.data.data.data || {};
                vm.single.copy_id = vm.single.id;
                vm.single.id = 0;
                vm.fields = response.data.data.fields;
                vm.edit_mode = true;
                vm.fields.forEach(x => {
                    if (x.type === 'picture' && !vm.single[x]) {
                        vm.single[x] = []
                    }
                });
            });
        },
        handleReload(data) {
            this.search = data;
            this.reload();
        },
        handleReview(id) {
            let vm = this;
            this.$api(this.model + '.Get', {id: id, review: true}, true).then(res => {
                vm.single = res.data.data;
                this.view_mode = true;
            });
        },
        findRecord(id) {
            for(let i=0;i<this.records.length;i++) {
                if (this.records[i].id == id)  return this.records[i];
            }
            return null;
        },
        handleDownloadReport(command,data,name) {
            this.$api(command,data,1,1).then(res=>{
                const fileURL = window.URL.createObjectURL(res.data);
                const fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", name);
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        },
        afterReload() {},
        beforeReload(){},
        getExtra(key){
            if (!this.extra_filters) return null;
            const selected = this.search.extra[key];
            if (!selected) return null;
            const one = this.extra_filters[key]['options'].filter(i=>i.key === selected);
            const result = one[0];
            return {'value':result.key,'label':result.value};
        },
        handleCalendarChange(data) {
            this.search.date_range = data;
            this.reload();
        }
    },
    mounted() {
        this.reload();
    },
}
