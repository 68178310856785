<template>
    <div class="el-form-item">
        <div class="el-form-item__content">
            <el-switch v-if="'switch'===item.type" v-model="extra[item.key]"
                       :active-value="'1'"
                       :inactive-value="'0'"
                       :active-text="item.title"
                       @change="$emit('change')"
            >
            </el-switch>
            <el-select v-if="'select'===item.type"
                       clearable filterable
                       v-model="extra[item.key]"
                       @change="$emit('change')"
                       :placeholder="item.title" size="small">
                <el-option v-for="(option,key) in item.options"
                           :label="Array.isArray(item.options)?option.value:option"
                           :value="(Array.isArray(item.options)?option.key:key)+''"
                           :key="(Array.isArray(item.options)?option.key:key)">
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
export default {
    name: "front-single-extra-filter",
    data() {
        return {}
    },
    watch:{
    },
    props: {
        item: {
            type: Object,
            default() {
                return null
            }
        },
        extra: {
            type: Object,
            default() {
                return {}
            }
        }
    }
}
</script>

<style scoped>

</style>
